

.carrousel-container {
  width: 100vw;
  height: 500px;
  margin-bottom: 100px;
  .swiper-slide {
    display: flex;
    align-items: center;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
