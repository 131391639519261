.wrapper {
  .contact-title {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 50px;
    margin-bottom: 80px;
    h2 {
      font-family: $open-sans;
      font-weight: $bold;
      font-size: $title-size;
      line-height: 50px;
    }
    p {
      font-family: $open-sans;
      font-weight: $bold;
      font-size: 24px;
      line-height: 32px;
      color: $dark-gray;
    }
  }
}

.contact-section {
  width: 100%;
  display: flex;
  margin-bottom: 100px;
  border: 1px solid rgba(196, 196, 196, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;


}

@media screen and (max-width: 1000px) {
  .wrapper {
    .contact-section {
      width: 100%;
      display: block;
      border: none;
      box-shadow: none;
      border-radius: 0px;
   
    }
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    .contact-section {
      width: 100%;
      display: block;
      border: none;
      box-shadow: none;
      border-radius: 0px;
 
    }
  }
}
