.lenguage-section {
  display: flex;
  justify-content: flex-end;
  button {
    background: none;
    border: none;
    margin-left: 5px;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
    }
  }
}
