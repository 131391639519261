.loading{
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    img{
        width: 300px;
        height: 300px;
    }
}