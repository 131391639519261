// import core files
@import './core/typography';

//import abstract files
@import './abstracts/variables';

// import components files
@import './components/commingSoon';
@import './components/footer';
@import './components/Introduction';
@import './components/navBar';
@import './components/cardImage';
@import './components/Loading';
@import './components/form';


// import pages files
@import './pages/about';
@import './pages/home';
@import './pages/contact';
@import './pages/galery';

// import layout files
@import './layout/wrapper';
@import './layout/div-gradient';

// import sections files
@import './sections/story';
@import './sections/objective';
@import './sections/mision';
@import './sections/donations';
@import './sections/volunteer';
@import './sections/lenguage-section';

* {
  margin: 0;
  padding: 0;
}

#root {
  width: 100vw;
}
