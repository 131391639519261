.donations-container {
  padding-top: 50px;
  background: linear-gradient(244.94deg, #5dc7d1 0%, #a9d046 100%);
  width: 100vw;
  margin-bottom: 200px;
  .donations-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-family: $open-sans;
      font-weight: $bold;
      font-size: $title-size;
      text-align: center;
      line-height: 40px;
      color: $white;
      padding-bottom: 50px;
    }

    p {
      font-family: $open-sans;
      font-weight: $semi-bold;
      font-size: $paragraph-size;
      text-align: center;
      color: $white;
      padding-bottom: 30px;
      width: 60%;
    }
  }

  .donations {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    width: 100%;
    position: relative;
    top: 80px;

    .card {
      display: flex;
      flex-direction: column;
      border-radius: 10px;
      background: $white;
      align-items: center;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      width: 500px;
      margin: 0 30px;
      img {
        width: 100px;
        height: 100px;
      }

      h2 {
        font-family: $open-sans;
        font-weight: $bold;
        font-size: 24px;
        color: $rose;
        margin-bottom: 10px;
      }

      p {
        font-family: $open-sans;
        font-weight: $semi-bold;
        font-size: 14px;
        color: #757575;
        margin-bottom: 20px;
        width: 50%;
      }

      a {
        padding: 15px 40px;
        margin-bottom: 20px;
        color: $white;
        font-family: $open-sans;
        font-weight: $bold;
        font-size: 18px;
        background: $blue;
        border-radius: 10px;
        text-decoration: none;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .donations-container {
      margin-bottom: 50px;
    .donations-text {
      h2 {
        font-size: 30px;
      }

      p {
        width: 100%;
      }
    }

    .donations {
      flex-direction: column;
      position: static;
      align-items: center;
      .card {
        margin: 0;
        width: 80%;
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .donations-container {
    .donations {
      .card {
        width: 100%;
      }
    }
  }
}
