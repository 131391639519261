//colors
$rose: #ed2790;
$blue: #5dc7d1;
$yellow: #ffcc2a;
$green: #a9d046;
$purple: #815aa4;
$orange: #f58750;
$black: #19253b;
$dark-gray: #757575;
$ligth-gray: #c4c4c4;
$white: #fffffe;
$gradient: linear-gradient(243.18deg, #5dc7d1 0%, #a9d046 100%);
//font
$open-sans: 'Open sans', 'sans-serif';
$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;
$extra-bold: 800;
$title-size: 36px;
$title-line-heigth: 50px;
$paragraph-size: 18px;

$p-size: 20px;
$p-line-heigth: 35px;
