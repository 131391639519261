.container {
  background: $gradient;
  color: #ffff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 60px 0;

  .buildLogo {
    width: 400px;
    height: 246px;
  }

  .donareLogo {
    width: 417px;
    height: 133px;
  }

  p {
    font-family: $open-sans;
    font-weight: $semi-bold;
    font-size: 35px;
  }
  h1 {
    font-family: $open-sans;
    font-weight: $extra-bold;
    font-size: 50px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    .buildLogo {
      width: 300px;
      height: 184px;
    }
    .donareLogo {
      width: 300px;
      height: 95px;
    }
    h1 {
      font-size: 40px;
    }

    p {
      font-size: 25px;
    }
  }
}
