.about-us {
  h1 {
    text-align: center;
    margin: 60px 0;
    font-family: $open-sans;
    font-weight: $bold;
    font-size: $title-size;
    line-height: $title-line-heigth;
    color: $black;
  }
  
}
