header {
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo img {
      width: 232px;
      height: 74px;
    }

    .butom {
      display: none;
    }

    .nav-bar {
      display: flex;
      justify-content: space-between;
      a {
        color: $black;
        margin-left: 50px;
        text-decoration: none;
        font-family: $open-sans;
        font-weight: $bold;
        font-size: 18px;
      }
      .active {
        color: $blue;
      }
      .donate-button {
        color: $white;
        padding: 10px 25px;
        background: $blue;
        border-radius: 5px;
        font-size: 18px;
        margin: 0 !important;
        &:active {
          color: $black;
        }
      }

      .navs {
        margin-right: 30px;
      }
    }
  }

  .navResponsive {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  header {
    nav {
      .logo {
        img {
          width: 180px;
          height: 57px;
        }
      }
      .butom {
        display: block;
        #check {
          display: none;
        }
        img {
          width: 30px;
          height: 30px;
        }
      }

      .nav-bar {
        display: none;
      }
    }

    .navResponsive {
      height: 100vh;
      display: block;
      position: fixed;
      left: -100%;
      text-align: center;
      width: 100%;

      ul {
        list-style: none;
        li {
          text-align: center;
          padding: 30px 0;

          a {
            text-decoration: none;
            font-family: $open-sans;
            color: $black;
            font-weight: $bold;
            font-size: 18px;
            
          }
          .active {
            color: $blue;
          }
        }
      }
    }
    .show {
      left: 0;
      background: $white;
      transition: all .5s;
    }
  }

  .fix{
    position: fixed;
  
  }
}


.show-carrousel {
  display: none;
  transition: all .9s;
}