.wrapper {
  margin: auto;
  padding: 0 80px;
  max-width: 1440px;
}

@media screen and (max-width: 800px) {
  .wrapper {
    padding: 0 40px;
  }
}

@media screen and (max-width: 500px) {
  .wrapper {
    padding: 0 12px;
    
  }
}