.objectives {
  display: flex;
  justify-content: center;
  .objectives-content {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
    .objectives-img {
      display: flex;
      justify-content: center;
      margin-left: 108px;
      img {
        width: 410px;
        height: 350px;
        border-radius: 10px;
      }
    }

    .objectives-paragraph {
      width: 50%;
      h2 {
        font-family: $open-sans;
        font-weight: $bold;
        font-size: $title-size;
        margin-bottom: 16px;
        color: $black;
        margin-bottom: 16px;
        span {
          color: $purple;
        }
      }
      p {
        // padding-right: 108px;
        max-width: 448px;
        font-family: $open-sans;
        font-weight: $semi-bold;
        font-size: $paragraph-size;
        line-height: 35px;
        color: $dark-gray;
        text-align: justify;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .objectives {
    display: flex;
    justify-content: center;
    .objectives-content {
      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      .objectives-img {
        display: flex;
        justify-content: center;
        margin-left: 0px;
        img {
          width: 410px;
          height: 350px;
          border-radius: 10px;
        }
      }

      .objectives-paragraph {
        width: 100%;
        h2 {
          font-family: $open-sans;
          font-weight: $bold;
          font-size: $title-size;
          margin-bottom: 16px;
          color: $black;
          margin-bottom: 16px;
          text-align: center;
          span {
            color: $purple;
          }
        }
        p {
          // padding-right: 108px;
          max-width: 448px;
          font-family: $open-sans;
          font-weight: $semi-bold;
          font-size: $paragraph-size;
          line-height: 35px;
          color: $dark-gray;
          text-align: justify;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .objectives .objectives-content .objectives-img img {
    width: 300px;
    height: 255px;
  }
}
