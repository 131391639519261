.error{
  color: #F44336;
  margin-bottom: 10px;
}
.contact-card {
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 26px 20px;
  color: $white;
  background: linear-gradient(29.32deg, #a9d046 1.1%, #5dc7d1 97.16%);
  border-radius: 20px;
  h2 {
    font-family: $open-sans;
    font-weight: $bold;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 16px;
    span {
      color: $rose;
    }
  }

  p {
    font-family: $open-sans;
    font-weight: $semi-bold;
    font-size: 18px;
    line-height: 35px;
    margin-bottom: 40px;
  }

  div {
    display: flex;
    img {
      width: 24px;
      height: 24px;
      margin-top: 7px;
      margin-right: 5px;
    }
  }
}

.form {
  width: 70%;
  padding: 26px 20px;
  font-family: $open-sans;
  font-weight: $semi-bold;
  font-size: 14px;
  line-height: 20px;
  color: $black;

  .first-last-name {
    display: flex;


    div {
      display: flex;
      flex-direction: column;
      width: 90%;

      input {
        width: 100%;
        padding: 15px 0;
        border: none;
        background: none;
        outline: none;
        border-bottom: 1px solid #c4c4c4;
        margin-bottom: 1px;
        margin-right: 20px;
      }
    }
  }

  .mail-phone {
    display: flex;
    div {
      display: flex;
      flex-direction: column;
      width: 90%;

      input {
        width: 100%;
        padding: 15px 0;
        border: none;
        background: none;
        outline: none;
        border-bottom: 1px solid #c4c4c4;
        margin-bottom: 1px;
        margin-right: 20px;
      }
    }
  }

  .message {
    display: flex;
    flex-direction: column;
    width: 95%;
    textarea {
      width: 100%;
      height: 100px;
      padding: 15px 0;
      border: none;
      background: none;
      outline: none;
      border-bottom: 1px solid #c4c4c4;
      margin-bottom: 16px;
      resize: none;
    }
  }

  .submit {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    button {
      padding: 15px 50px;
      text-decoration: none;
      border-radius: 10px;
      background: $blue;
      font-size: $open-sans;
      font-weight: $semi-bold;
      font-size: 18px;
      line-height: 24px;
      color: $white;
      cursor: pointer;
      border: none;
    }
  }
}

@media screen and (max-width: 1000px) {
  .contact-card {
    margin: auto;
    width: 80%;
    h2 {
      font-size: 25px;
    }
    p {
      width: 90%;
    }
    div {
      width: 90%;
    }
  }

  .form {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .contact-card {
    width: 80%;
    div {
      p {
        font-size: 15px;
      }
    }
  }

  .form {
    width: 90%;
  }
}
