.div-gradient {
  padding: 87px 0;
  background: linear-gradient(244.94deg, #5dc7d1 0%, #a9d046 100%);
  border-radius: 0 100px;
  margin: 60px 0px 160px 0px;
}

@media screen and (max-width: 1000px) {
  .div-gradient {
    padding: 87px 0;
    background: linear-gradient(244.94deg, #5dc7d1 0%, #a9d046 100%);
    border-radius: 0 100px;
    margin: 60px 0px 60px 0px;
  }
  
}